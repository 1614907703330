var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:_vm.groupLabel,staticClass:"text-input app-text-input",class:{
    'active': _vm.isActiveLabel,
    'styling-default': _vm.styling === 'default',
    'styling-inline-inverse': _vm.styling === 'inline-inverse',
    'styling-inline': _vm.styling === 'inline',
    'disabled': _vm.disabled,
    /* 'not-valid': required && !validateVal */
    /* 'not-valid': required && !validateVal */
  }},[_c('input-label',{attrs:{"active":_vm.isActiveLabel}},[_vm._v(" "+_vm._s(_vm.label)+" ")]),(_vm.hint)?_c('div',{staticClass:"hint"},[_vm._v(" "+_vm._s(_vm.isUnit ? _vm.$store.getters['unit/getLabel'](_vm.hint) : _vm.hint)+" ")]):_vm._e(),(_vm.infoTip)?_c('div',{staticClass:"tip-container"},[_c('info-tip',{attrs:{"size":"18","title":_vm.infoTip,"position":'right'}})],1):_vm._e(),_c('input',{attrs:{"type":_vm.type,"placeholder":_vm.placeholder,"autocomplete":_vm.autocomplete,"required":!!_vm.required,"maxlength":_vm.maxlength,"minlength":_vm.minlength,"min":_vm.min,"step":_vm.step,"max":_vm.max,"disabled":_vm.disabled,"onkeypress":_vm.onkeypress},domProps:{"value":_vm.get_value()},on:{"blur":_vm.onInput,"focus":_vm.onFocus}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }