var render = function render(){var _vm=this,_c=_vm._self._c;return _c(_vm.to ? 'router-link' : 'button',{tag:"component",staticClass:"btn h-btn app-btn",class:{
    'disabled': _vm.disabled,
    'outline': _vm.styling === 'outline',
    'styling-inline': _vm.styling === 'inline',
    'default': _vm.styling === 'default',
    'size-normal': _vm.size === 'normal',
    'size-big': _vm.size === 'big',
    'loading': _vm.loading,
    'danger': _vm.danger,
    'danger-outline': _vm.styling == 'danger-outline',
  },attrs:{"to":_vm.to,"type":"button"},on:{"click":_vm.onClick}},[_c('div',{staticClass:"content"},[(_vm.icon)?_c('MatIcon',{attrs:{"size":_vm.size,"icon":_vm.icon}}):_vm._e(),_vm._t("default"),_c('transition',{attrs:{"name":"fade"}},[(_vm.loading)?_c('LoadingSVG'):_vm._e()],1)],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }